import React from 'react'
import Query from './Query'
import styled from 'styled-components'

const Container = styled.div`
  color: ${({ theme }) => theme.colors.text};
  text-align: center;
  font-weight: 300;
  letter-spacing: 0.15em;
  position: relative;
`

const MainTitle = styled.h1`
  font-size: 2.8em;
  line-height: 1.17em;
  max-width: 60rem;
  margin: 0 auto 0.8em auto;
`

const SubTitle = styled.h2`
  font-size: 1em;
  line-height: 1.67em;
`

export default () => (
  <Query
    render={markdown => (
      <Container>
        <MainTitle>{markdown.frontmatter.content_title}</MainTitle>
        <SubTitle>{markdown.frontmatter.subtitle}</SubTitle>
      </Container>
    )}
  />
)
